import "./App.css"
import { Route, HashRouter, Routes } from "react-router-dom"
import Notice from "./pages/Notice"
import AU from "./pages/AU"
import NZ from "./pages/NZ"
import Promo from "./pages/Promo"
import PromoList from "./pages/Promo/PromoList"

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/au/:uid" element={<AU />} />
        <Route path="/nz/:uid" element={<NZ />} />
        <Route path='/notice' element={<Notice />} />
        <Route path='/promo' element={<Promo />} />
        <Route path='/promo/list/:uid' element={<PromoList />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
