import React, {useEffect} from "react"
import {Form, Layout, Input, Button, message, Select, DatePicker, Typography} from 'antd'
import {endpoints} from "../../lib/endpoint"
import axios from "axios"
import {
    useParams
} from "react-router-dom"
import moment from "moment"

const NZ = () => {
    let uid = useParams()?.uid
    const [form] = Form.useForm()
    const [editable, setEditable] = React.useState(false)
    const [informationFilled, setInformationFilled] = React.useState(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}${endpoints.auClient}?uuid=${uid}&product=NZ`).then(res => {
            if (res.status === 200) {
                form.setFieldsValue({
                    ...res.data,
                    taobaoid: res.data?.client?.taobaoid,
                    passport_expire_date: res.data.passport_expire_date ? moment(res.data.passport_expire_date, 'YYYY-MM-DD') : moment(),
                    birthday: res.data.birthday ? moment(res.data.birthday, 'YYYY-MM-DD') : moment(),
                    when_been_to_nz: res.data.when_been_to_nz ? moment(res.data.when_been_to_nz, 'YYYY-MM-DD') : moment(),
                    id_start: res.data.id_start ? moment(res.data.id_start, 'YYYY-MM-DD') : moment(),
                    id_end: res.data.id_end ? moment(res.data.id_end, 'YYYY-MM-DD') : moment(),
                    been_to_nz: res.data.been_to_nz ? "true" : "false",

                    pay_by_own_card: res.data.pay_by_own_card ? "true" : "false",
                    pay_by_us_if_failed: res.data.pay_by_us_if_failed ? "true" : "false",
                    this_time: res.data.this_time ? "true" : "false",

                })
                setEditable(res.data?.client?.editable)
                setInformationFilled(res.data?.client?.information_filled)

            } else {
                message.error('链接无效，请检查后重试')
            }
        })
    }, [uid, form, setEditable])
    console.log(informationFilled)

    const addInfo = (values) => {
        const info = {
            ...values,
            passport_expire_date: values.passport_expire_date?.format('YYYY-MM-DD'),
            birthday: values.birthday?.format('YYYY-MM-DD'),
            id_start: values.id_start?.format('YYYY-MM-DD'),
            id_end: values.id_end?.format('YYYY-MM-DD'),
            been_to_nz: values.been_to_nz === "true",
            when_been_to_nz: values.when_been_to_nz?.format('YYYY-MM-DD'),
            pay_by_own_card: values.pay_by_own_card === "true",
            pay_by_us_if_failed: values.pay_by_us_if_failed === "true",
            this_time: values.this_time === "true",

        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoints.auClient}`, {
            ...info,
            unqiue_hash_id: uid,
            product: "NZ"
        }).then(res => {
            if (res.status === 200) {
                message.info('提交成功')
            } else {
                message.error('提交失败，请重试')
            }

        })
    }

    const layout = {
        labelCol: {
            xs: {span: 12},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 12},
            sm: {span: 12},
        },
    }

    return (
        <Layout style={{margin: "10px"}}>
            <Layout.Header style={{
                backgroundColor: "#f0f2f5",
                display: "flex",
                justifyContent: "center"
            }}>蠢萌小袋鼠-新西兰信息登记</Layout.Header>
            <Layout.Content>

                <Typography.Text level={3} style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center"
                }}>为了防止有人转卖名额引来不必要的麻烦和复杂度，希望您三天内可以登记下信息，主要是您的姓名部分；<br/>护照还没办理的，可以先随意写一个，后面记得及时修改。</Typography.Text>
                <Form form={form} onFinish={addInfo}
                      {...layout}
                      style={{margin: "10px"}}
                >
                    <Form.Item
                        label="淘宝ID"
                        name="taobaoid"
                        disabled    // 只读
                        rules={[{required: true, message: '请输入您的淘宝ID'}]}
                    >
                        <Input disabled/>
                    </Form.Item>

                    <Form.Item
                        label="First Name (名字拼音）"
                        name="first_name"
                        rules={[{required: true, message: '请输入名字拼音'}, {
                            pattern: /^[a-zA-Z0-9_ ]+$/,
                            message: '请输入拼音'
                        }]}
                        disabled={informationFilled}
                    >
                        <Input disabled={informationFilled}/>
                    </Form.Item>
                    <Form.Item
                        label="Last Name（姓氏拼音）"
                        name="last_name"
                        disabled={informationFilled}
                        rules={[{required: true, message: '请输入姓氏拼音'}, {
                            pattern: /^[a-zA-Z0-9_ ]+$/,
                            message: '请输入拼音'
                        }]}
                    >
                        <Input disabled={informationFilled}/>
                    </Form.Item>


                    <Form.Item label="性别" name="gender" rules={[{required: true}]}>
                        <Select>
                            <Select.Option value="Male">Male</Select.Option>
                            <Select.Option value="Female">Female</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="护照号码"
                        name="passport"
                        rules={[{required: true, message: '请输入护照号码'}]}
                    >
                        <Input disabled={informationFilled}/>
                    </Form.Item>
                    <Form.Item
                        label="护照过期日期"
                        name="passport_expire_date"
                        rules={[{required: true, message: '请选择护照过期日期'}]}
                    >
                        <DatePicker format={'YYYY-MM-DD'}/>
                    </Form.Item>

                    <Form.Item
                        label="出生日期"
                        name="birthday"
                        rules={[{required: true, message: '请选择出生日期'}]}
                    >
                        <DatePicker format={'YYYY-MM-DD'}/>
                    </Form.Item>

                    <Form.Item
                        label="身份证开始日期"
                        name="id_start"
                        rules={[{required: true, message: '身份证件开始日期'}]}
                    >
                        <DatePicker format={'YYYY-MM-DD'}/>
                    </Form.Item>
                    <Form.Item
                        label="身份证过期日期"
                        name="id_end"
                        rules={[{required: true, message: '身份证件开始日期'}]}
                    >
                        <DatePicker format={'YYYY-MM-DD'}/>
                    </Form.Item>

                    <Form.Item label="是否去过新西兰" name="been_to_nz" rules={[{required: true}]}>
                        <Select>
                            <Select.Option value="true">是</Select.Option>
                            <Select.Option value="false">否</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.been_to_nz !== currentValues.been_to_nz}
                    >
                        {({getFieldValue}) =>
                            getFieldValue('been_to_nz') === 'true' ? (
                                <Form.Item name="when_been_to_nz" label="上次入境新西兰时间" rules={[{required: true}]}>
                                    <DatePicker format={'YYYY-MM-DD'}/>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        label="City（居住地所在城市）"
                        name="address_city"
                        rules={[{required: true, message: '请输入所在城市拼音'}, {
                            pattern: /^[a-zA-Z0-9_ ]+$/,
                            message: '请输入拼音'
                        }]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Suburb（居住地所在区）"
                        name="address_suburb"
                        rules={[{required: true, message: '请输入所属区/县/市拼音'}, {
                            pattern: /^[a-zA-Z0-9_ ]+$/,
                            message: '请输入拼音'
                        }]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="地址（居住地所在街道）"
                        name="address_street"
                        rules={[{required: true, message: '请输入街道地址拼音'}, {
                            pattern: /^[a-zA-Z0-9_ ]+$/,
                            message: '请输入拼音'
                        }]}
                    >
                        <Input/>
                    </Form.Item>


                    <Form.Item label="使用自己的卡付款" name="pay_by_own_card" rules={[{required: true}]}>
                        <Select>
                            <Select.Option value="true">是</Select.Option>
                            <Select.Option value="false">否</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}
                    >
                        {({getFieldValue}) =>
                            getFieldValue('pay_by_own_card') === 'true' ? (

                                <Form.Item label="四次付款失败后，用我们的卡付款" name="pay_by_us_if_failed"
                                           rules={[{required: true}]}>
                                    <Select>
                                        <Select.Option value="true">是</Select.Option>
                                        <Select.Option value="false">否</Select.Option>
                                    </Select>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}
                    >
                        {({getFieldValue}) =>
                            getFieldValue('pay_by_own_card') === 'true' ? (

                                <Form.Item label="卡类别" name="card_type" rules={[{required: true}]}>
                                    <Select>
                                        <Select.Option value="MASTERCARD">MasterCard</Select.Option>
                                        <Select.Option value="VISA">Visa</Select.Option>
                                    </Select>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}
                    >
                        {({getFieldValue}) =>
                            getFieldValue('pay_by_own_card') === 'true' ? (
                                <Form.Item label="卡号" name="card_number" rules={[{required: true}]}>
                                    <Input/>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>


                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}
                    >
                        {({getFieldValue}) =>
                            getFieldValue('pay_by_own_card') === 'true' ? (
                                <Form.Item label="卡过期月份" name="card_expire_month" rules={[{required: true}]}>
                                    <Select>
                                        <Select.Option value="1">1</Select.Option>
                                        <Select.Option value="2">2</Select.Option>
                                        <Select.Option value="3">3</Select.Option>
                                        <Select.Option value="4">4</Select.Option>
                                        <Select.Option value="5">5</Select.Option>
                                        <Select.Option value="6">6</Select.Option>
                                        <Select.Option value="7">7</Select.Option>
                                        <Select.Option value="8">8</Select.Option>
                                        <Select.Option value="9">9</Select.Option>
                                        <Select.Option value="10">10</Select.Option>
                                        <Select.Option value="11">11</Select.Option>
                                        <Select.Option value="12">12</Select.Option>
                                    </Select>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}
                    >
                        {({getFieldValue}) =>
                            getFieldValue('pay_by_own_card') === 'true' ? (
                                <Form.Item label="卡过期年份" name="card_expire_year" rules={[{required: true}]}>
                                    <Select>
                                        <Select.Option value="2022">2022</Select.Option>
                                        <Select.Option value="2023">2023</Select.Option>
                                        <Select.Option value="2024">2024</Select.Option>
                                        <Select.Option value="2025">2025</Select.Option>
                                        <Select.Option value="2026">2026</Select.Option>
                                        <Select.Option value="2027">2027</Select.Option>
                                        <Select.Option value="2028">2028</Select.Option>
                                        <Select.Option value="2029">2029</Select.Option>
                                        <Select.Option value="2030">2030</Select.Option>
                                        <Select.Option value="2031">2031</Select.Option>
                                        <Select.Option value="2032">2032</Select.Option>
                                        <Select.Option value="2033">2033</Select.Option>
                                    </Select>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}
                    >
                        {({getFieldValue}) =>
                            getFieldValue('pay_by_own_card') === 'true' ? (
                                <Form.Item label="安全码（CVV）" name="cvv" rules={[{required: true}]}>
                                    <Input/>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.pay_by_own_card !== currentValues.pay_by_own_card}
                    >
                        {({getFieldValue}) =>
                            getFieldValue('pay_by_own_card') === 'true' ? (
                                <Form.Item label="持卡人姓名（拼音）" name="card_holder_name" rules={[{required: true}]}>
                                    <Input/>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{required: true, message: '请输入邮件地址', type: "email"}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="手机号码"
                        name="phone"
                        rules={[{required: true, message: '请输入电话号码', pattern: '^([-]?[1-9][0-9]*|0)$'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item label="是否抢最近这批次" name="this_time" rules={[{required: true}]}>
                        <Select>
                            <Select.Option value="true">是</Select.Option>
                            <Select.Option value="false">否</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="备注"
                        name="comments"
                    >
                        <Input.TextArea rows={3}/>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            xs: {span: 24, offset: 0},
                            sm: {span: 12, offset: 6},
                        }}>
                        <Button type="primary" htmlType="submit" block disabled={!editable}>
                            提交
                        </Button>
                    </Form.Item>

                </Form>


            </Layout.Content>
            <Layout.Footer style={{display: "flex", justifyContent: "center"}}>蠢萌小袋鼠</Layout.Footer>
        </Layout>
    )
}

export default NZ