import React, { useEffect } from "react"
import { Form, Layout, Input, Button, message, Select, DatePicker } from 'antd'
import { endpoints } from "../../lib/endpoint"
import axios from "axios"
import {
    useParams
} from "react-router-dom"
import moment from "moment"

const AU = () => {
    let uid = useParams()?.uid
    const [form] = Form.useForm()
    const [editable, setEditable] = React.useState(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}${endpoints.auClient}?uuid=${uid}`).then(res => {
            if (res.status === 200) {
                form.setFieldsValue({
                    ...res.data,
                    taobaoid: res.data?.client?.taobaoid,
                    passport_expire_date: res.data.passport_expire_date ? moment(res.data.passport_expire_date, 'YYYY-MM-DD') : moment(),
                    birth_date: res.data.birth_date ? moment(res.data.birth_date, 'YYYY-MM-DD') : moment(),
                    want_date: res.data.want_date ? moment(res.data.want_date, 'YYYY-MM-DD') : moment(),

                })
                setEditable(res.data?.client?.editable)

            } else {
                message.error('链接无效，请检查后重试')
            }
        })
    }, [uid, form, setEditable])

    const addInfo = (values) => {
        const info = {
            ...values,
            passport_expire_date: values.passport_expire_date.format('YYYY-MM-DD'),
            birth_date: values.birth_date.format('YYYY-MM-DD'),
            want_date: values.want_date.format('YYYY-MM-DD'),

        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoints.auClient}`, { ...info, unqiue_hash_id: uid, product: "AU" }).then(res => {
            if (res.status === 200) {
                message.info('提交成功')
            } else {
                message.error('提交失败，请重试')
            }

        })
    }

    const layout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 12 },
        },
    }
    return (
        <Layout style={{ margin: "10px" }}>
            <Layout.Header style={{ backgroundColor: "#f0f2f5", display: "flex", justifyContent: "center" }}>蠢萌小袋鼠-澳洲信息登记</Layout.Header>
            <Layout.Content>
                <Form form={form} onFinish={addInfo}
                    style={{ margin: "10px" }}
                    {...layout}
                    disabled={editable}
                >
                    <Form.Item
                        label="淘宝ID"
                        name="taobaoid"
                        disabled    // 只读
                        rules={[{ required: true, message: '请输入您的淘宝ID' }]}
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        label="First Name"
                        name="first_name"
                        rules={[{ required: true, message: '请输入名字拼音' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="last_name"
                        rules={[{ required: true, message: '请输入姓氏拼音' }]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item label="性别" name="gender" rules={[{ required: true }]} >
                        <Select>
                            <Select.Option value="Male">Male</Select.Option>
                            <Select.Option value="Female">Female</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="护照号码"
                        name="passport"
                        rules={[{ required: true, message: '请输入护照号码' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="护照过期日期"
                        name="passport_expire_date"
                        rules={[{ required: true, message: '请选择护照过期日期' }]}
                    >
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>

                    <Form.Item
                        label="出生日期"
                        name="birth_date"
                        rules={[{ required: true, message: '请选择出生日期' }]}
                    >
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>

                    <Form.Item
                        label="期望日期"
                        name="want_date"
                        rules={[{ required: true, message: '请选择期望预约日期，我们尽力约' }]}
                    >
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: '请输入邮件地址', type: "email" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="手机号码"
                        name="phone"
                        rules={[{ required: true, message: '请输入电话号码', pattern: '^([-]?[1-9][0-9]*|0)$' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="comments"
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            xs: { span: 24, offset: 0 },
                            sm: { span: 12, offset: 6 },
                        }}>
                        <Button type="primary" htmlType="submit" block disabled={!editable}>
                            提交
                        </Button>
                    </Form.Item>

                </Form>


            </Layout.Content>
            <Layout.Footer style={{ display: "flex", justifyContent: "center" }}>蠢萌小袋鼠</Layout.Footer>
        </Layout >
    )
}

export default AU