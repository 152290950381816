import React, { useState } from "react"
import { Form, Layout, Input, Button, Row, Col, Checkbox, Typography } from 'antd'
import { endpoints } from "../../lib/endpoint"
import axios from "axios"

const Notice = () => {
    const [groups, setGroups] = useState(['au', 'nz'])
    const [submitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState("")
    const onFinish = (values) => {
        let nz = true
        let au = true
        if (!groups.includes('nz')) {
            nz = false
        }
        if (!groups.includes('au')) {
            au = false
        }
        axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoints.notice}`, { ...values, au, nz }).then(res => {
            if (res.status === 200) {
                setSubmitted(true)
            } else {
                setSubmitError("Something went wrong. Please try again later.")
            }
        })
    }


    const options = [
        { label: '澳洲WHV', value: 'au' },
        { label: '新西兰WHV', value: 'nz' },
    ]

    return (
        <Layout >
            <Layout.Header style={{ backgroundColor: "#f0f2f5", display: "flex", justifyContent: "center" }}>蠢萌小袋鼠-贴心通知</Layout.Header>
            <Layout.Content>
                <Row>
                    <Col span={8} />
                    <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
                        {
                            submitted ? <Typography.Text type="success">已经收到您的登记，会在有消息的第一时间给您通知。</Typography.Text> : <Form
                                name="basic"
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="淘宝ID"
                                    name="taobaoid"
                                    rules={[{ required: true, message: '请输入您的淘宝ID' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, message: '请输入邮件地址', type: "email" }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="手机号码"
                                    name="phone"
                                    rules={[{ required: true, message: '请输入电话号码', pattern: '^([-]?[1-9][0-9]*|0)$' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item >
                                    <Checkbox.Group style={{ display: "flex", justifyContent: "space-around" }} options={options} defaultValue={groups} onChange={e => setGroups(e)} />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block >
                                        提交
                                    </Button>
                                </Form.Item>
                                {submitError && <Typography.Text type="danger">{submitError}</Typography.Text>}
                            </Form>
                        }
                    </Col>
                    <Col span={8} />
                </Row>

            </Layout.Content>
            <Layout.Footer style={{ display: "flex", justifyContent: "center" }}>蠢萌小袋鼠</Layout.Footer>
        </Layout>
    )
}

export default Notice