import React, { useState } from "react"
import { Form, Layout, Input, Button, Row, Col, Typography, Select } from 'antd'
import { endpoints } from "../../lib/endpoint"
import axios from "axios"

const Promo = () => {
    const [group, setGroup] = useState("nz")
    const [submitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState("")
    const onFinish = (values) => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoints.auPromo}`, { ...values, group }).then(res => {
            if (res.status === 200) {
                if (res.data?.success) {
                    setSubmitted(true)
                } else {
                    setSubmitError(res.data?.message)
                }
            } else {
                setSubmitError("Something went wrong. Please try again later.")
            }
        })
    }


    const options = [
        // { label: '澳洲WHV', value: 'au' },
        { label: '新西兰WHV', value: 'nz' },
    ]

    return (
        <Layout >
            <Layout.Header style={{ backgroundColor: "#f0f2f5", display: "flex", justifyContent: "center" }}>蠢萌小袋鼠-推荐人登记</Layout.Header>
            <Layout.Content>
                <Row>
                    <Col span={8} />
                    <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
                        {
                            submitted ? <Typography.Text type="success">已经收到您的登记，付尾款时告知客服抵扣。</Typography.Text> : <Form
                                name="basic"
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item>
                                    <Select defaultValue="au" value={group} onChange={e => setGroup(e)}>
                                        {options.map(option => (
                                            <Select.Option value={option.value}>{option.label}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="您的淘宝ID"
                                    name="taobaoid"
                                    rules={[{ required: true, message: '请输入您的淘宝ID' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="推荐人手机号码"
                                    name="phone"
                                    rules={[{ required: true, message: '请输入电话号码', pattern: '^([-]?[1-9][0-9]*|0)$' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block >
                                        提交
                                    </Button>
                                </Form.Item>
                                {submitError && <Typography.Text type="danger">{submitError}</Typography.Text>}
                            </Form>
                        }
                    </Col>
                    <Col span={8} />
                </Row>

            </Layout.Content>
            <Layout.Footer style={{ display: "flex", justifyContent: "center" }}>蠢萌小袋鼠</Layout.Footer>
        </Layout>
    )
}

export default Promo