import React, { useEffect, useState } from "react"
import { Layout, message, Table } from 'antd'
import { endpoints } from "../../lib/endpoint"
import axios from "axios"
import {
    useParams
} from "react-router-dom"

const PromoList = () => {
    let uid = useParams()?.uid
    const [data, setData] = useState([])
    const columns = [
        {
            title: 'Taobaoid',
            dataIndex: 'taobaoid',
            key: 'taobaoid',
            render: text => <p>{text}</p>,
        }]

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}${endpoints.auPromo}?uuid=${uid}`).then(res => {
            if (res.status === 200 && res.data?.success) {
                console.log(res.data)
                setData(res.data?.data?.taobaoids.map(item => ({
                    taobaoid: item
                })))

            } else {
                message.error('链接无效，请检查后重试')
            }
        })
    }, [uid, setData])

    return (
        <Layout >
            <Layout.Header style={{ backgroundColor: "#f0f2f5", display: "flex", justifyContent: "center" }}>蠢萌小袋鼠-您的推广计划成果</Layout.Header>
            <Layout.Content>
                <Table columns={columns} dataSource={data} summary={() => (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={1}>Total: {data?.length}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )} />

            </Layout.Content>
            <Layout.Footer style={{ display: "flex", justifyContent: "center" }}>蠢萌小袋鼠</Layout.Footer>
        </Layout >
    )
}

export default PromoList
